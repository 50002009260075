import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"

const Icon = ({ frontmatter, className, height, width, link }) => {
  return link ? (
    <Link to={link} className={`icon ${className}`}>
      <figure>
        <img
          className="icon__asset"
          src={frontmatter.icon.publicURL}
          alt={`${frontmatter.text} logo`}
          style={{ height, width }}
        />
        {frontmatter.text ? (
          <figcaption className="icon__text">{frontmatter.text}</figcaption>
        ) : null}
      </figure>
    </Link>
  ) : (
    <figure className={`icon ${className}`}>
      <img
        className="icon__asset"
        src={frontmatter.icon.publicURL}
        alt={`${frontmatter.text} logo`}
        style={{ height, width }}
      />
      {frontmatter.text ? (
        <figcaption className="icon__text">{frontmatter.text}</figcaption>
      ) : null}
    </figure>
  )
}

Icon.defaultProps = {
  frontmatter: {},
  className: "",
  height: "4rem",
  width: "4rem",
}

Icon.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default Icon
