import React from "react"
import { Link } from "gatsby"

const ArchiveCard = ({ post, className, handleSelected }) => {
  const { excerpt, frontmatter } = post,
    { title, path, topics, featuredImage } = frontmatter

  let handleClick = topic => {
    handleSelected(topic)
  }
  return (
    <Link className="archive-card__wrapper" to={path}>
      <article className={`archive-card ${className}`}>
        <div className="archive-card__content">
          <figure className="archive-card__image">
            <img src={featuredImage.publicURL} alt="" />
          </figure>

          <div className="archive-card__text">
            <h1>{title}</h1>
            <p>{excerpt}</p>
            <Link className="archive-card__more" to={path}>
              Read More
            </Link>
          </div>
        </div>
        <ul className="topic-list">
          {topics.sort().map((topic, i) => (
            <li className="topic-list__item" key={i}>
              <button
                onClick={() => {
                  handleClick(topic)
                }}
              >
                {topic}
              </button>
            </li>
          ))}
        </ul>
      </article>
    </Link>
  )
}

export default ArchiveCard
