import React from "react"
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"
import BlogFeed from "../components/BlogFeed"
import HeroBlogArchive from "../components/HeroBlogArchive"
import ContactForm from "../components/ContactForm"
import TopicCheckbox from "../components/TopicCheckbox"
import safeGetImage from "../utils/safeGetImage"

export default function Archive({ location }) {
  const query = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/blog/" }) {
        frontmatter {
          title
          path
          pinToTop
        }
      }
      posts: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/posts/" } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              title
              path
              topics
              featuredImage {
                publicURL
              }
            }
          }
        }
      }
      topics: allMdx {
        group(field: frontmatter___topics) {
          topic: fieldValue
          totalCount
        }
      }
    }
  `)

  const { pinToTop } = query.page.frontmatter
  const posts = query.posts.edges

  // get featured post
  const featured = posts.filter(post => {
    return post.node.frontmatter.path === pinToTop
  })

  // get all topics
  const topics = query.topics.group.map(topic => topic.topic)

  // if user has come from a topic link on another page get the topic passed through as state
  let checked = location.state && location.state.checked

  // setup up array of topics to filter by and set initial state
  // if user has come from another page, initial array includes the topic selected
  let [filterTopics, setFilterTopics] = useState(() => {
    return checked ? [checked] : []
  })

  // function that changes the state of the list of filtered topics triggered by checkbox onChange
  // pass in name of topic selected
  // this data is being passed 'up' from TopicCheckbox component

  const handleSelected = topic => {
    if (filterTopics.indexOf(topic) === -1) {
      setFilterTopics([...filterTopics, topic])
    } else {
      let removed = [...filterTopics].filter(item => item !== topic)
      setFilterTopics(removed)
    }
  }

  // filter non-featured posts by topic
  const filteredPosts = []

  posts.forEach(post => {
    filterTopics.forEach(topic => {
      if (
        post.node.frontmatter.topics.includes(topic) &&
        !filteredPosts.includes(post)
      ) {
        filteredPosts.push(post)
      }
    })
  })

  // topics list toggle
  const [toggled, setToggle] = useState(false)
  const handleToggle = () => {
    setToggle(!toggled)
  }
  return (
    <>
      <SEO title="Archive" />
      <HeroBlogArchive featured={ featured[0] } />
      <section className="blog-archive">
        <BlogFeed
          handleSelected={ handleSelected }
          posts={ filterTopics.length > 0 ? filteredPosts : posts }
        />
        <aside className="topic-checkboxes">
          <button onClick={ handleToggle } className="topic-checkboxes__toggle">
            <h2 className="topic-checkboxes__title">Filter by topic</h2>
            <figure
              className={ `topic-checkboxes__toggle--down ${ toggled && "hidden"
                }` }
            >
              <img
                src={ `${ safeGetImage("arrow-down.svg") }` }
                alt="Show More Button"
              />
            </figure>
            <figure
              className={ `topic-checkboxes__toggle--up ${ !toggled && "hidden" }` }
            >
              <img
                src={ `${ safeGetImage("arrow-down.svg") }` }
                alt="Show Less Button"
              />
            </figure>
          </button>
          <h2 className="topic-checkboxes__title--wide">Filter by topic</h2>
          <ul className={ `topic-checkboxes__list ${ !toggled && "hidden" }` }>
            { topics.sort().map((topic, i) => (
              <TopicCheckbox
                checked={ filterTopics }
                handleSelected={ handleSelected }
                topic={ topic }
                key={ i }
              />
            )) }
          </ul>
        </aside>
      </section>
      <ContactForm />
    </>
  )
}
