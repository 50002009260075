import React from "react"
import Button from "./core/Button"
import PropTypes from "prop-types"
import Icon from "../components/core/Icon"
import { MDXRenderer } from "gatsby-plugin-mdx"

const TextBlock = ({
  children,
  pageTitle,
  title,
  subtitle,
  text,
  icon,
  button,
  panelDescription,
  className,

}) => {
  return (
    <div className={`text-card ${className || ""}`}>
      {pageTitle && <h1 className="text-card__page-title">{pageTitle}</h1>}

      {panelDescription && (
        <p className="text-card__description">{panelDescription}</p>
      )}
      {icon && (
        <Icon
          frontmatter={icon}
          link={false}
          className="technologies__icon--hero"
        />
      )}
      {title && <h2 className="text-card__title">{title}</h2>}

      {subtitle && <p className="text-card__subtitle">{subtitle}</p>}
     
      { text && 
          !children &&
          text.length && (
            <div className="text-card__content">
              {text.split("\n").map((para, i) => (
                <p key={i}>{para}</p>
              ))}
            </div>
          )}

      {children && (
        <MDXRenderer className="text-card__content">{children}</MDXRenderer>
      )}
      <div className="text-card__button">
      {button && <Button button={button} />}
      </div>
    </div>
  )
}

TextBlock.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.node,
  button: PropTypes.shape({
    colour: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
  }),
  panelDescription: PropTypes.string,
  // children: PropTypes.node,
}

export default TextBlock
