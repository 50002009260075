import React from "react"
import PropTypes from "prop-types"

const TopicCheckbox = ({ topic, handleSelected, checked }) => {
  const handleCheck = topic => {
    handleSelected(topic)
  }
  return (
    <>
      <label className="topic-checkbox">
        <input
          onChange={() => {
            handleCheck(topic)
          }}
          className="topic-checkbox__input"
          type="checkbox"
          name={topic}
          checked={checked.filter(item => item === topic)[0] === topic}
        />
        <span className="topic-checkbox__span"></span>
        <p className="topic-checkbox__label">{topic}</p>
      </label>
    </>
  )
}

TopicCheckbox.propTypes = {
  topic: PropTypes.string,
}

export default TopicCheckbox
