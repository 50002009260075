import React from "react"
import ArchiveCard from "../components/ArchiveCard"

const BlogFeed = ({ posts, handleSelected }) => {
  return (
    <div className="blog-feed">
      {posts.length > 0 ? (
        posts.map(({ node: post }, i) => {
          return (
            <ArchiveCard
              handleSelected={handleSelected}
              key={i}
              post={post}
              className="blog-feed__item"
            />
          )
        })
      ) : (
        <p>There are no posts to show.</p>
      )}
    </div>
  )
}

export default BlogFeed
