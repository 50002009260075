import React from "react"
import Panel from "./core/Panel"
import TextBlock from "./TextBlock"
import Link from "gatsby-link"

const HeroBlogArchive = ({ featured }) => {
  const { featuredImage, title, path } = featured.node.frontmatter
  return (
    <Panel
      backgroundImage={`url(${featuredImage.publicURL})`}
      className="blog-archive__hero"
    >
      <Link to={path}>
        <TextBlock
          panelDescription={"Featured Blog"}
          title={title}
          text={featured.node.excerpt}
        />
      </Link>
    </Panel>
  )
}

export default HeroBlogArchive
